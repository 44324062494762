/**
 * Created by LiuXinai on 19/06/2017.
 */
export const ID = '@contacts'

export default {
  ID: ID,
  GET_ROLE_DefsAndDept: `${ID}/GET_ROLE_DefsAndDept`,
  GET_API_CONFIG: `${ID}/GET_API_CONFIG`,
  EDIT_API_CONFIG: `${ID}/EDIT_API_CONFIG`,
  GET_STAFF_ACTIVE_INFO: `${ID}/GET_STAFF_ACTIVE_INFO`,
  GET_LOG_LIST: `${ID}/GET_LOG_LIST`,
  IS_CONSISTENT_WITH_ACCOUNT: `${ID}/IS_CONSISTENT_WITH_ACCOUNT`,
  SAVE_USERINFO: `${ID}/SAVE_USERINFO`,
  GET_APPLY_INFO: `${ID}/GET_APPLY_INFO`,
  APPLY_APPROVAL: `${ID}/APPLY_APPROVAL`,
  GET_INVITEINFO: `${ID}/GET_INVITEINFO`,
  CORPORATIONS: `${ID}/CORPORATIONS`,
  CREATE_DEPTMENT: `${ID}/CREATE_DEPTMENT`,
  GET_STAFF_LIST: `${ID}/GET_STAFF_LIST`,
  GET_INACTIVE_STAFF_LIST: `${ID}/GET_INACTIVE_STAFF_LIST`,
  DEL_EXTERNAL: `${ID}/DEL_EXTERNAL`,
  ADD_STAFF_EXTERNAL: `${ID}/ADD_STAFF_EXTERNAL`,
  UPDATE_STAFF_EXTERNAL: `${ID}/UPDATE_STAFF_EXTERNAL`,
  ADD_STAFF_EXTERNAL_PLANTFORM: `${ID}/ADD_STAFF_EXTERNAL_PLANTFORM`,
  GET_ORGANIZATION_CONFIG: `${ID}/GET_ORGANIZATION_CONFIG`,
  MODIFY_ORGANIZATION_CONFIG: `${ID}/MODIFY_ORGANIZATION_CONFIG`,
  GET_SETTING_AUTO: `${ID}/GET_SETTING_AUTO`,
  GET_EMPLOYEES: `${ID}/GET_EMPLOYEES`,
  // GET_STAFF_PROPERTYSET: Symbol(`${ID}/GET_STAFF_PROPERTYSET`),
  // SAVE_STAFF_FIELD: Symbol(`${ID}/SAVE_STAFF_FIELD`),
  GET_STAFF_PROPERTYSET: `${ID}/GET_STAFF_PROPERTYSET`,
  SAVE_STAFF_FIELD: `${ID}/SAVE_STAFF_FIELD`,
  DISABLED_STAFF_FIELD: `${ID}/DISABLED_STAFF_FIELD`,
  STAFF_BATCH_CHANGE_DEPARTMENT: `${ID}/STAFF_BATCH_CHANGE_DEPARTMENT`,
  STAFF_CHECK_CHANGE_TENANT: `${ID}/STAFF_CHECK_CHANGE_TENANT`,
  CROSS_TENANT_DEPARTMENT_UPDATE: `${ID}/CROSS_TENANT_DEPARTMENT_UPDATE`,
}
