// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import {
  getEmployees,
  getStaffInfo,
  getApiConfig,
  editApiConfig,
  checkConsistentWithAccountInfo,
  saveQywxContactsSynchronous,
  getRoleDefsAndDept,
  getAuto,
  getStaffPropertySet
} from './contacts-action'
import { findDeptPath, roleDescribe } from './utils'

export const Universal_Unique_Key = 'contacts.pc'

export default [
  {
    id: '@contacts',
    reducer: () => import('./contacts-reducer'),
    path: '/contacts',
    ref: '/',
    onload: () => import('./contacts-view'),
    onbefore: async (app) => {
      const DepartmentsTreeModal = await import('./models/DepartmentsTreeModal')
      app.collection.register(DepartmentsTreeModal.default)
    },
    onfirst: async (app) => {
      api.dataLoader('@common.powers').load()
    },
    getStaffInfoById: (id) => {
      return getStaffInfo(id)
    },
    'check:consistent:with:account:info': (params) => api.dispatch(checkConsistentWithAccountInfo(params)),
    'get:employees': (params) => api.dispatch(getEmployees(params)),
    'get:RoleDefs:Dept': () => api.dispatch(getRoleDefsAndDept()),
    'get:Auto': () => api.dispatch(getAuto()),
    'import:findDeptPath': () => findDeptPath,
    'import:roleDescribe': () => roleDescribe,
    'get:api:config': () => api.dispatch(getApiConfig()),
    'edit:api:config': (params) => api.dispatch(editApiConfig(params)),
    'save:qywx:contacts:synchronous': (params) => saveQywxContactsSynchronous(params),
    'get:staff:dynamic:property': () => getStaffPropertySet()
  },
  {
    point: '@@layers',
    prefix: '@contacts',
    onload: () => import('./layers')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'contacts',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 4,
        label: '通讯录',
        actived: true,
        href: '/contacts',
        icon: 'contacts'
      }
    ]
  }
]
