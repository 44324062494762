import { Resource } from '@ekuaibao/fetch'
import key from './key'
import { showMessage, showModal } from '@ekuaibao/show-util'

const permission = new Resource('/api/v1/organization/permissions/byStaffId')
const rsaveuser = new Resource('/api/v1/organization/staffs')
const dept = new Resource('/api/v1/organization/departments')
const externalDept = new Resource('/api/v1/organization/externalDepartment')
const appStaffs = new Resource('/api/app/organization/staffs')
const externalStaffs = new Resource('/api/v1/organization/staffs')
const apiStaffs = new Resource('/api/organization/staffs')
const flowAction = new Resource('/api/flow/v2/flows')
const organization = new Resource('/api/v1/organization')
const setOrganization = new Resource('/api/v2/organization')
const qywxSecert = new Resource('/api/qyweixin/v2/secret')
const synOrg = new Resource('/api/nbbank/v1/syn/org')
const sync = new Resource('/api/qyweixin/v2/sync')
const propertySet = new Resource('/api/flow/v2/propertySet')
const roleDefsAndDept = new Resource('/api/v1/organization/roleGroups/roleDefsAndDept')
const powers = new Resource('/api/charge/powers')
const resign = new Resource('/api/v1/resign')
const groupDepartment = new Resource('/api/v1/group/center/department')
const urlMap = { WEIXIN: '/api/qyweixin/v2/userId', DING_TALK: '/api/dingtalk/v2/userId' }

// 获取角色树（包括部门和档案）
export function getRoleDefsAndDept() {
  return {
    type: key.GET_ROLE_DefsAndDept,
    payload: roleDefsAndDept.GET('')
  }
}

// 获取通讯录同步相关配置
export function getApiConfig() {
  return {
    type: key.GET_API_CONFIG,
    payload: setOrganization.GET('/apiConfig')
  }
}

// 修改通讯录同步相关配置
export function editApiConfig(params) {
  return {
    type: key.EDIT_API_CONFIG,
    payload: setOrganization.PUT('/apiConfig', params)
  }
}
export function getStaffActiveInfo(userId) {
  const url = new Resource(urlMap[window.__PLANTFORM__])
  return {
    type: key.GET_STAFF_ACTIVE_INFO,
    payload: url.GET('/$userId', { userId })
  }
}

export function getLog(data) {
  return {
    type: key.GET_LOG_LIST,
    payload: organization.POST('/logs/staff/log', data)
  }
}

export function getUserPermission(user) {
  const params = { id: user.id }
  return permission.GET('/$id', params)
}

export function saveUser(data) {
  if (!data.id) return appStaffs.POST('', data)
  data.sourceChannel = window.__PLANTFORM__
  return rsaveuser.PUT('/$id', data)
}

export function updateDeptment(data) {
  const { id } = data
  if (!id) return createDeptment(data)
  return dept.PUT('/update/$id', { id, ...data })
}

export function leaveTransfer(param, type) {
  if (type === 'transfer') {
    return groupDepartment.POST('/staffChangeHandover', param)
  } else {
    return flowAction.PUT('/relay', param)
  }
}

export function getApplyInfo(data) {
  return {
    type: key.GET_APPLY_INFO,
    payload: appStaffs.POST('/apply/approve/query', data)
  }
}

export function applyApproval(data) {
  return {
    type: key.APPLY_APPROVAL,
    payload: appStaffs.PUT('/apply/approve', data)
  }
}

export function getInviteInfo(data) {
  return {
    type: key.GET_INVITEINFO,
    payload: appStaffs.POST('/invite', data)
  }
}

export function deleteDeptment(id) {
  return dept.DELETE('/$id', { id })
}

export function createDeptment(data) {
  return dept.POST('', data)
}

export function updateExternalDeptment(data) {
  let { id } = data
  if (!id) return createExternalDeptment(data)
  return externalDept.PUT('/$id', { id, ...data })
}
export function deleteExternalDeptment(id) {
  return externalDept.DELETE('/$id', { id })
}

export function createExternalDeptment(data) {
  return externalDept.POST('', data)
}
export function deleteStaff(ids) {
  if (ids.length == 1) {
    const id = ids[0]
    return appStaffs.PUT('/quit/$id', { id })
  } else {
    return appStaffs.PUT('/quit/[ids]', { ids })
  }
}

export function getCorporations(data) {
  return {
    type: key.CORPORATIONS,
    payload: organization.GET('/corporations', data)
  }
}

export function editStaffDeptment(data) {
  return appStaffs.PUT('/changeDepart', data)
}

export function editExternalStaffDeptment(data) {
  //后端反馈 网关原因外部环境不能使用 api/app。。。接口
  return externalStaffs.PUT('/changeDepart', data)
}

export function getStaffInfo(id) {
  // let join = {
  //   join: `departments,departments,/v1/organization/departments`
  // }
  return organization.GET('/staffs/$id', { id })
}
export function getStaffList(params) {
  return {
    type: key.GET_STAFF_LIST,
    payload: rsaveuser.GET('/actives', params)
  }
}
//获取未激活员工
export function getStaffInactive(searchText) {
  const value = { start: 0, count: 2999, external: false, range: true, active: false }
  const filterBy = `(name.contains("${searchText}")) || (code.contains("${searchText}"))`
  return {
    type: key.GET_INACTIVE_STAFF_LIST,
    payload: rsaveuser.POST('/actives', { filterBy }, value)
  }
}

export function delExternal(param) {
  return {
    type: key.DEL_EXTERNAL,
    payload: rsaveuser.DELETE('/[id]', param)
  }
}
// 原生平台添加外部人员接口
export function addStaffExternal(params) {
  return {
    type: key.ADD_STAFF_EXTERNAL,
    payload: appStaffs.POST('', params)
  }
}
// 外部平台添加外部人员接口
export function addStaffExternalPlantform(params) {
  return {
    type: key.ADD_STAFF_EXTERNAL_PLANTFORM,
    payload: rsaveuser.POST('', params)
  }
}

export function updateStaffExternal(id, params) {
  params.id = id
  return {
    type: key.UPDATE_STAFF_EXTERNAL,
    payload: rsaveuser.PUT('/$id', params)
  }
}

export function checkConsistentWithAccountInfo(params) {
  return {
    type: key.IS_CONSISTENT_WITH_ACCOUNT,
    payload: rsaveuser.GET('/checkInfo/$id', params)
  }
}

export function getEmployees(params) {
  return {
    type: key.GET_EMPLOYEES,
    payload: synOrg.GET('', params)
  }
}

// 获取通讯录配置
export function getOrganizationConfig() {
  return {
    type: key.GET_ORGANIZATION_CONFIG,
    payload: setOrganization.GET('/config')
  }
}

export function modifyOrganizationConfig(params) {
  return {
    type: key.MODIFY_ORGANIZATION_CONFIG,
    payload: setOrganization.PUT('/config', params)
  }
}

export function checkQywxContactsSynchronous() {
  return qywxSecert.GET('/get')
}

export function saveQywxContactsSynchronous(params) {
  return qywxSecert.POST('/save', params)
}

export function getAuto() {
  return {
    type: key.GET_SETTING_AUTO,
    payload: sync.GET('/setting/auto')
  }
}

export function syncSetting(data) {
  return sync.PUT('/setting', null, data)
}

export function getSyncInfo() {
  return sync.GET('/getSyncInfo')
}

export function getStaffPropertySet() {
  return getPropertySetByType({ type: 'STAFF' })
}

export function getPropertySetByType(params) {
  return propertySet.GET('/$type', params, { cache: 'no-cache', skipCache: true })
}

export function saveStaffField(data) {
  return {
    type: key.SAVE_STAFF_FIELD,
    payload: propertySet.POST('/property', { ...data })
  }
}

export function DisabledStaffField({ name, active }) {
  return {
    type: key.DISABLED_STAFF_FIELD,
    payload: propertySet.POST('/property/staff/$name/$active', { name, active })
  }
}

export function getExportType() {
  return apiStaffs.POST('/app/excel/exportType')
}

export function getStaffListAsync(params) {
  return setOrganization.POST('/staffs/list', params).catch((e) => {
    showMessage.error(e.message || e.msg)
  })
}

export function setStaffAuthState(params) {
  return apiStaffs.POST('/auth/authStaff', params).catch((e) => {
    showModal.error({
      title: i18n.get('操作失败'),
      content: i18n.get(e.message || e.msg)
    })
  })
}

export function putPowersAutoAdd(autoAdd) {
  return powers.PUT(`/autoAdd/$powerCode/${autoAdd}`, { powerCode: '110101' }).catch((e) => {
    showMessage.error(e.message || e.msg)
  })
}

export function getPowersAutoAdd() {
  return powers.GET(`/detail/$powerCode`, { powerCode: '110101' }).catch((e) => {
    showMessage.error(e.message || e.msg)
  })
}

export function getAuthState() {
  return apiStaffs.GET('/auth/info', undefined, undefined, { skipCache: true }).catch((e) => {
    showMessage.error(e.message || e.msg)
  })
}

// 保存授权已开通员工
export function saveOpenStaffs(params, done) {
  return powers.POST('/authStaff', params)
}

/**
 * 获取所有待离职交接的任务
 * @returns {Promise<any>}
 */
export function getResignAllTask() {
  return resign.GET('/all')
}

/**
 * 获取待离职员工人员正在交接的任务
 * @param params {quitStaffIds: string[], relayStaffId: string}
 * @returns {Promise<any>}
 */
export function getProcessingResignTask(params) {
  return resign.POST('/processing', params)
}

/**
 * 开始执行离职交接
 * @param params {quitStaffIds: string[], relayStaffId: string, jobs: string[]}
 * @returns {Promise<any>}
 */
export function executeResignTask(params) {
  return resign.POST('/execute', params)
}

/**
 * 获取正在进行交接中的任务状态
 * @param params {batchId: string}
 * @returns {Promise<any>}
 */
export function getResignTaskDetail(params) {
  return resign.GET('/detail', params)
}

/**
 * 获取交接历史列表
 * @param params {page: number, size: number, ...}
 * @returns {Promise<any>}
 */
export function getAllResignBatch(params) {
  return resign.POST('/allBatch', params)
}

/**
 * 获取是否有未读离职交接任务通知
 * @returns {Promise<any>}
 */
export function getResignUnRead() {
  return resign.GET('/read')
}
/**
 * 已读离职交接任务通知
 * @returns {Promise<any>}
 */
export function readResignMessage() {
  return resign.POST('/read')
}

/**
 * 重试失败批次
 * @param params: {batchId: string}
 * @returns {Promise<any>}
 */
export function retryBatch(params) {
  return resign.POST('/retry', null, params)
}

/**
 * 刷新离职交接进度
 * @param params: {batchId: string}
 * @returns {Promise<any>}
 */
export function refreshBatch(params) {
  return resign.GET('/refresh', params)
}

// 数据中心校验是否跨租户人员有未完成单据等数据
export function staffBatchChangeDepartment(params) {
  return {
    type: key.STAFF_BATCH_CHANGE_DEPARTMENT,
    payload: groupDepartment.POST('/staffBatchChangeDepartment', params)
  }
}

// 数据中心校验员工是否跨租户
export function staffCheckChangeTenant(staffId, departmentIds) {
  return {
    type: key.STAFF_CHECK_CHANGE_TENANT,
    payload: groupDepartment.POST('/staffCheckChangeTenant/$staffId', { staffId, departmentIds })
  }
}

// 数据中心移动部门获取未完成单据员工
export function crossTenantDepartmentUpdate(departmentId, parentId) {
  return {
    type: key.CROSS_TENANT_DEPARTMENT_UPDATE,
    payload: groupDepartment.POST('/crossTenantDepartmentUpdate/$departmentId', { departmentId, parentId })
  }
}
