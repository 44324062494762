import { app as api } from '@ekuaibao/whispered'
import { getV, sequenceStaffs } from '@ekuaibao/lib/lib/help'
import { get } from 'lodash'

export function unfoldDepartmentTree(deptTree) {
  if (!deptTree) return
  let deptMap = {}
  deptTree.map((item, index) => {
    deptMap[item.id] = item
    if (item.children && item.children.length > 0) {
      const deptM = unfoldDepartmentTree(item.children)
      deptMap = {
        ...deptMap,
        ...deptM
      }
    }
  })
  return deptMap
}

export function isShow() {
  if (window.__PLANTFORM__ === 'WEIXIN' && !isApi()) {
    return true
  }
  return false
}

export function searchDepart(searchText, deptMap) {
  const result = Object.keys(deptMap).filter((item) => {
    return !!~deptMap[item].name.indexOf(searchText)
  })
  return result
}

export function searchMember(searchText, staffs) {
  const result = staffs.filter((item) => {
    return !!~item.name.indexOf(searchText) || !!~item.code.indexOf(searchText)
  })
  return result
}

export function findDeptPath(dept, deptMap) {
  if (!deptMap || !dept) return ''
  let result = dept.name
  if (!!dept.parentId) {
    const parent = deptMap[dept.parentId]
    if (!!parent) {
      const a = findDeptPath(parent, deptMap)
      result = a + i18n.get('／') + result
    }
  }

  return result
}

export function findStaffsInDepart(staffs, dept, authStaffDepartmentStaffMap) {
  const deptMap = unfoldDepartmentTree([dept])
  const allIds = Object.keys(deptMap)
  let result = []
  if (authStaffDepartmentStaffMap) {
    allIds.map((deptId) => {
      const staffList = authStaffDepartmentStaffMap[deptId]?.staffList
      if (staffList?.length) {
        result = result.concat(staffList)
      }
    })
    const { list } = result.reduce(
      (res, staff) => {
        if (!res.map[staff.id]) {
          res.map[staff.id] = staff
          res.list.push(staff)
        }
        return res
      },
      { map: {}, list: [] }
    )
    result = list
  } else {
    allIds.map((e) => {
      staffs.map((item) => {
        if (!!~item.departments.indexOf(e) && !~result.indexOf(item)) {
          result.push(item)
        }
      })
    })
  }
  return sequenceStaffs(result, getV(dept, 'id', ''))
}

export function roleDescribe(role, deptMap) {
  let result = ''
  if (!!role.properties.departmentId) {
    result = '(' + findDeptPath(deptMap[role.properties.departmentId], deptMap) + ')'
  } else if (!!role.properties.项目) {
    result = '(' + get(role, 'properties.project.fullName') + ')'
  }

  return result
}

export function isApi() {
  const contactAPI = api.getState('@common').powers.ContactOpenAPI //通讯录是API控制的不允许手动在企业中维护通讯录
  const openApi = api.getState('@common').powers.OpenAPI
  return contactAPI && openApi
}

export function isEditable(key, apiConfig) {
  if ('MC' === window.__PLANTFORM__ || 'APP' === window.__PLANTFORM__ || 'HYBRID' === window.__PLANTFORM__) {
    if (isApi()) {
      // 如果开通了：API通讯录&手动维护(PowerCode：219906)后，管理员将开启兼容手工维护模式。
      const manuallyMaintainOpenApiContact = api.getState('@common').powers.ManuallyMaintainOpenApiContact
      if (manuallyMaintainOpenApiContact) {
        switch (key) {
          case 'deptEdit': {
            return true
          }
          case 'deptAdd': {
            return get(apiConfig, 'apiDeptAdd.allowDeptAdd')
          }
          case 'staffAdd': {
            return get(apiConfig, 'apiStaffAdd.allowStaffAdd')
          }
          case 'staffModify': {
            return get(apiConfig, 'apiStaffModify.allowStaffModify')
          }
        }
      }
    } else {
      return true
    }
  }
  return false
}

export function disableMC(data = {}, targetOperateName = '') {
  // TODO 暂时先注释掉给测试进行其他功能测试
  return true
  // const type = getV(data, 'type', '');
  // if (data.hasOwnProperty('type') && type === 'FREEDOM') {
  //   return true;
  // }
  // const permissions = getV(data, 'permissions', []);
  // if (permissions.length) {
  //   const allName = 'ALL';
  //   const targetPermission =
  //     permissions.find(
  //       (item) => item.name === targetOperateName.toUpperCase() || item.name === allName,
  //     ) || {};
  //   return !!targetPermission.auth;
  // }
  // return false;
}

export function getParentId(items = [], parentMapper = {}) {
  items.forEach((item) => {
    parentMapper[item.id] = item.parentId
    if (item.children && item.children.length) {
      getParentId(item.children, parentMapper)
    }
  })
  return parentMapper
}

export const awaitWrap = (promise) => {
  return promise.then((res) => [null, res]).catch((err) => [err, null])
}
